
.menu { position: absolute; bottom: -140px; transition: all 0.5s; width: 100%;  z-index: 2;}
.menu.hidden {opacity: 0; bottom: -120px!important;}
.menu * {font-family: 'Poppins-regular'; color: #525047; }
.menu.expand {bottom: 3px;}
.menu .menu-wrapper {background-color: #C3C4C1; height: 110px;}
.menu .menu-part {display: flex; justify-content: center; align-items: center;}
.menu .icon-wrapper {border-radius: 50%; border: 2px solid #525047; margin: 5px; display: flex; justify-content: center; align-items: center; cursor: pointer; transition: background-color 0.3s;}
.menu .icon-wrapper img, .menu .icon-wrapper svg {transition: transform 0.3s;}
.menu .icon-wrapper svg {transform: scale(0.8);}
.menu .icon-wrapper.speaker-wrapper svg {transform: scale(0.9);}
.menu .icon-wrapper.info-wrapper svg {transform: scale(1);}
.menu .icon-wrapper.info-wrapper svg #Layer0_0_1_leftInfo {fill: none;}

/* .menu .icon-wrapper:hover svg {transform: scale(1);} */

.menu .menu-left {flex-direction: column; background-color: #CCC8C2; width: 100px; height: 112px; border: 1px solid #525047;}
.menu .menu-left .icon-wrapper {width: 45px; height: 45px;}
.menu .menu-left .icon-wrapper:hover {background-color: #525047;}
.menu .menu-left .icon-wrapper svg path {fill: white;}
.menu .menu-left .icon-wrapper.mute .wave {display: none;}

.menu .menu-middle .menu-item { flex-direction: column; width: 115px; position: relative;}
.menu .menu-middle .menu-item.wide { width: 135px;}
.menu .menu-middle .menu-item .icon-wrapper {width: 70px; height: 70px; background-color: #9A9388;}
.menu .menu-middle .menu-item.active .icon-wrapper {background-color: #525047;}
.menu .menu-middle .menu-item .icon-wrapper img {transform: scale(0.6);}
.menu .menu-middle .menu-item.info .icon-wrapper img {transform: scale(0.9) translate(0.5px, 1px);}
.menu .menu-middle .menu-item .icon-wrapper:hover {background-color: #525047;}
/* .menu .menu-middle .menu-item .icon-wrapper:hover img {transform: scale(0.95);} */
.menu .menu-item .menu-label {font-size: 14px;}

.menu .menu-middle .menu-item .tooltip-wrapper { position: absolute; top: 0px; width: 700px; z-index: -1; opacity: 0; display: flex; justify-content: center; transform: scaleX(0.3); transition: all 0.3s; }
.menu .menu-middle .menu-item:has(.icon-wrapper:hover) .tooltip-wrapper { top: -65px; opacity: 1; transform: scaleX(1);}
.menu .menu-middle .menu-item .tooltip-inner { border-radius: 10px; padding: 10px 20px; text-align: center; background-color: white; font-size: 16px;}
.menu .menu-middle .menu-item .tooltip-arrow { width: 0;  height: 0;  border-left: 16px solid transparent; border-right: 16px solid transparent; border-top: 16px solid white; position: absolute; bottom: -16px;}

.menu .menu-middle .stick-part {display: flex; height: 110px; border-right: 1px solid #525047;}
.menu .menu-middle .stick-part .back-label {position: relative; font-size: 18px; cursor: pointer; padding-top: 5px;}
.menu .menu-middle .stick-part .back-label .back-icon {position: absolute; top: 15px; left: 5px; width: 10px; height: 10px; border-left: 1px solid #525047; border-bottom: 1px solid #525047; transform: rotate(45deg);}
.menu .menu-middle .stick-part .back-label label { margin-left: 15px;}
.menu .menu-middle .stick-part .back-label:hover label { color: black; cursor: pointer;}

.menu .menu-middle .stick-wrapper { height: 110px;}
.menu .menu-middle .stick-wrapper.conSubDeckHorizon { display: flex; align-items: center;}
.menu .menu-middle .stick-wrapper .stick-item-wrapper { height: 50%; width: 230px; float: left;}
.menu .menu-middle .stick-wrapper .stick-item { height: 38px; width: calc(100% - 42px); display: flex; align-items: center; padding: 0px 10px; border: 1px solid #525047; transition: background-color 0.3s; cursor: pointer; position: relative;}
.menu .menu-middle .stick-wrapper .stick-item * {cursor: pointer;}
.menu .menu-middle .stick-wrapper .stick-item .color-wrapper { height: 24px; width: 60px; border: 1px solid;}
.menu .menu-middle .stick-wrapper .stick-item .stick-label { margin-left: 5px; transition: color 0.3s; line-height: 120%; text-align: center; margin: auto; flex-direction: column; display: flex;}
.menu .menu-middle .stick-wrapper .stick-item .stick-label label {font-size: 15px;}
.menu .menu-middle .stick-wrapper .stick-item:hover { background-color: #525047;}
.menu .menu-middle .stick-wrapper .stick-item:hover .color-wrapper { border-color: white;}
.menu .menu-middle .stick-wrapper .stick-item:hover .stick-label label { color: white;}
.menu .menu-middle .stick-wrapper .stick-item .color-wrapper.mix { background-image: url('../images/menu/mix-rail.jpg'); background-size: contain;}

.menu .menu-middle .stick-wrapper .stick-item.active { background-color: #525047;}
.menu .menu-middle .stick-wrapper .stick-item.active .color-wrapper { border-color: white;}
.menu .menu-middle .stick-wrapper .stick-item.active .stick-label label { color: white;}

.menu .menu-middle .stick-wrapper.conMainDeck {display: flex; align-items: center;}
.menu .menu-middle .stick-wrapper.conMainDeck .stick-item {justify-content: center;}
.menu .menu-middle .stick-wrapper.goodMainDeck {display: flex; align-items: center;}
.menu .menu-middle .stick-wrapper.goodMainDeck .stick-item {justify-content: center;}
.menu .menu-middle .stick-wrapper.goodSubDeckWeekender {display: flex; align-items: center;}

.menu .menu-middle .stick-wrapper.furniture .stick-item-wrapper { width: 130px;}
.menu .menu-middle .stick-wrapper.furniture .stick-item {width: 100px;}
.menu .menu-middle .stick-wrapper.furniture .stick-item .color-wrapper {width: 100px;}
.menu .menu-middle .stick-wrapper.furniture .stick-item .stick-label { position: absolute; top: 0px; left: -2px; margin-left: 0; border: 1px solid; background-color: rgba(255, 255, 255, 0.8); padding: 2px; width: calc(100% - 2px); font-size: 14px; opacity: 0; transition: all 0.2s; color: black;}
.menu .menu-middle .stick-wrapper.furniture .stick-item:hover .stick-label { top: -28px; left: -2px; opacity: 1;}

.menu .menu-right {height: 112px; background-color: #CCC8C2; border: 1px solid #525047;}
.menu .menu-right .small-icons {padding: 0px 10px;}
.menu .menu-right .big-icon {padding-right: 10px;}
.menu .menu-right .small-icons .icon-wrapper {width: 40px; height: 40px; border-width: 1px;}
.menu .menu-right .small-icons .menu-label {cursor: pointer;}
.menu .menu-right .big-icon .icon-wrapper {width: 45px; height: 45px; background-color: #525047;}
.menu .menu-right .big-icon .icon-wrapper svg {transform: scale(0.7);}
.menu .menu-right .big-icon .icon-wrapper.active {background-color: white;}
.menu .menu-right .big-icon .icon-wrapper.active svg path {fill: #525047;}
.menu .menu-right .big-icon .icon-wrapper.night {background-color: #DEDFDD;}
.menu .menu-right .big-icon .icon-wrapper.night:hover {background-color: #FFFFFF;}

.menu .menu-wrapper .menu-collapse {position: absolute; cursor: pointer; top: -60px; width: 150px; height: 30px; background-color: #DEDFDD; border-top-left-radius: 10px; border-top-right-radius: 10px; left: calc(50% - 75px); transition: all 0.5s;}
.menu .menu-wrapper .menu-collapse.hide {top: 0px; opacity: 0;}
