.tool-modal-wrapper {opacity: 0; padding: 0 !important;}
.tool-modal-wrapper.active { margin: auto; opacity: 1; padding: 20px!important}
.object-modal .tool-modal-wrapper.active {padding: 0!important;}

.tool-modal-wrapper .top-bar {display: flex; height: 40px; align-items: center; margin-bottom: 10px;}
.tool-modal-wrapper .top-bar .heart {width: 40px; height: 40px;}
.tool-modal-wrapper .top-bar .heart .inside {fill: none; transition: all 0.3s;}
.tool-modal-wrapper .top-bar .heart .outline {stroke: #525047; transition: all 0.3s;}
.tool-modal-wrapper .top-bar .heart.active .inside {fill: red;}
.tool-modal-wrapper .top-bar .heart.active .outline {stroke: red;}
.tool-modal-wrapper .top-bar .button.download {padding: 5px 10px; margin-left: 15px;}

.tool-modal-wrapper .pdf-content {width: calc(100% - 10px); height: 100%;}
.tool-modal-wrapper .image-content {width: 100%; height: 100%;}
.tool-modal-wrapper .image-content.single-image img {max-width: 100%; max-height: 100%;}

.tool-modal-wrapper.tv-page .pdf-content {height: calc(100% - 60px);}
.tool-modal-wrapper.tv-page .image-content {height: calc(100% - 50px);}

.tool-modal-wrapper .image-content .main-image {width: 100%; height: calc(100% - 100px);display: flex; align-items: center; justify-content: center;flex-direction: column;}
.tool-modal-wrapper .image-content .main-image img {max-width: calc(100% - 20px); max-height: calc(100% - 20px);}
.tool-modal-wrapper .image-content .tumb-wrapper {max-width: 100%; overflow-x: auto; height: 100px;}
.tool-modal-wrapper .image-content .tumb-wrapper .tumb-inner{ height: 100%; margin: auto;}
.tool-modal-wrapper .image-content .tumb-wrapper .tumb-inner .tumb-img {width: 80px; min-width: 80px; height: 80px; border: 1px solid #525047; margin: 0px 5px; float: left;}
.tool-modal-wrapper .image-content .tumb-wrapper .tumb-inner .tumb-img.active {border-color: #f74c19;}
.tool-modal-wrapper .image-content .tumb-wrapper .tumb-inner .tumb-img img {max-width: 100%; max-height: 100%;}
.tool-modal-wrapper .image-content img.black {background-color: black;}

.tool-modal-wrapper .image-content .main-image.with-label img {max-height: calc(100% - 40px);}
.tool-modal-wrapper .image-content .main-image.with-label .img-label { font-size: 18px; font-weight: 700;}

.tool-modal-wrapper .image-content .download {position: absolute; top: 10px; left: 10px;}
.tool-modal-wrapper .pdf-content .download {position: absolute; top: 10px; left: 10px; height: 30px;}

.mobile .tool-modal-wrapper .image-content .main-image {height: calc(100% - 60px);}
.mobile .tool-modal-wrapper .image-content .main-image.with-label .img-label {font-size: 14px;}
.mobile .tool-modal-wrapper .image-content .main-image.with-label img {max-height: calc(100% - 25px);}
.mobile .tool-modal-wrapper .image-content .tumb-wrapper { height: 60px;}
.mobile .tool-modal-wrapper .image-content .tumb-wrapper .tumb-inner .tumb-img {height: 45px; width: 70px;}