@font-face {
	font-family: 'Poppins-regular';
	src: url("../font/Poppins-Regular.ttf");
}
@font-face {
	font-family: 'Poppins-medium';
	src: url("../font/Poppins-Medium.ttf");
}
@font-face {
	font-family: 'Raleway-regular';
	src: url("../font/Raleway-Regular.ttf");
}
@font-face {
	font-family: 'Raleway-medium';
	src: url("../font/Raleway-Medium.ttf");
}
@font-face {
	font-family: 'Mangal-bold';
	src: url("../font/mangalb.ttf");
}
